import React from "react";
import { connect } from "react-redux";
import ReactWhatsapp from 'react-whatsapp';


function CustomChatbot(props) {
  
  const {number , name} = props;

      
    let original = number && (number.length !== 0) && (number.substring(0,2) === "03") ? "+923"+number.substring(2, number.length) : (number.substring(0,2) !== "03") ? "+"+number : "03331203726";
   
  //  let originalChange = original.replace("+","");
   
 let data = `<i class="fa fa-whatsapp" style="font-size:36px;color:#fff;"></i>`;
return (
  
<>
{original && (original.length !== 0) ?

<ReactWhatsapp       dangerouslySetInnerHTML={{__html: data}}
  className="myWhatsapp" number={original.replace("+","")} message="Hello!"  />
  : null
}

</>

 );
   }
   const mapStateToProps = (state) => ({
    number: (state && state.contactDetails && state.contactDetails.contactDetails && state.contactDetails.contactDetails.data && state.contactDetails.contactDetails.data.whatsapp_number)?state.contactDetails.contactDetails.data.whatsapp_number:'',
    name: state,
  })
  export default connect(mapStateToProps)(CustomChatbot);
