import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SlideUpDown } from "../../../services/script"
import "./footer.css"
import { Link } from 'react-router-dom';
import ScrollToTop from '../../pages/scroll_to_top';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class FooterFour extends Component {
    componentDidMount() {
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('slide-up');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function (elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }



    render() {
        const { sociallinks, contactDetails, categories, appconfigs, footerIcons } = this.props;


        const colorCodes = localStorage.getItem("color_theme");
        let parsedColorCodes = JSON.parse(colorCodes);
        let appconfig = parsedColorCodes && (parsedColorCodes !== null) ? parsedColorCodes : appconfigs;

        let checkPaymentArr = (paymentA) => {
            return paymentA.type === "payment"
        }
        let checkShippingArr = (shipmentA) => {
            return shipmentA.type === "shipping"
        }

        let paymentArr = footerIcons && (footerIcons.length !== 0) && footerIcons.filter(checkPaymentArr);
        let shippingArr = footerIcons && (footerIcons.length !== 0) && footerIcons.filter(checkShippingArr);;


        const styleString = sociallinks?.width ?? ''; // Provide a default value if sociallinks?.width is undefined
        const widthh = styleString.match(/width:\s*([^,]+)/)?.[1] || '';
        const heightt = styleString.match(/height:\s*([^,]+)/)?.[1] || '';



        return (
            <footer className="footer-style" style={{ bottom: 0, width: "100%", left: 0, }}>
                <ScrollToTop />
                <div className="white-layout" >
                    <div className="container">
                        <section className="small-section" style={{ marginTop: 70, padding: 0 }}>
                        </section>
                    </div>
                </div>
                <footer className='footersa'>
                    <div className="content">
                        <div className="top">
                            <div className="logo-details">

                                <Link to={`${process.env.PUBLIC_URL}/`}>
                                    {
                                        (sociallinks && sociallinks?.logo) ?
                                            <LazyLoadImage width={widthh} height={heightt} src={sociallinks && sociallinks?.logo} alt='Tradnity' />
                                            :
                                            <div className="skeleton-item skeleton-images" ></div>
                                    }

                                </Link>
                            </div>
                            <div>
                                <a href={(contactDetails.data) ? contactDetails.data.facebook_page : "/#"} target="_blank" rel="noopener noreferrer">
                                    {((sociallinks.data) ? sociallinks.data[0].is_active : '' === 1) ? <LazyLoadImage alt="as" src={(sociallinks.data) ? sociallinks.data[0].icon : ''} width={60} height={60} /> : ''}
                                </a>
                                <a href={(contactDetails.data) ? contactDetails.data.instagram_page : "/#"} target="_blank" rel="noopener noreferrer">
                                    {((sociallinks.data) ? sociallinks.data[1].is_active : '' === 1) ? <LazyLoadImage alt="as" src={(sociallinks.data) ? sociallinks.data[1].icon : ''} width={60} height={60} /> : ''}
                                </a>
                                <a href={(contactDetails.data) ? contactDetails.data.twitter_page : "/#"} target="_blank" rel="noopener noreferrer">
                                    {((sociallinks.data) ? sociallinks.data[2].is_active : '' === 1) ? <LazyLoadImage alt="as" src={(sociallinks.data) ? sociallinks.data[2].icon : ''} width={60} height={60} /> : ''}
                                </a>
                                <a href={(contactDetails.data) ? contactDetails.data.youtube_page : "/#"} target="_blank" rel="noopener noreferrer">
                                    {((sociallinks.data) ? sociallinks.data[3].is_active : '' === 1) ? <LazyLoadImage alt="as" src={(sociallinks.data) ? sociallinks.data[3].icon : ''} width={60} height={60} /> : ''}
                                </a>
                            </div>
                        </div>
                        <div className="link-boxes top" style={{ paddingTop: 0 }}
                        >


                            {appconfig && appconfig?.footer_new_arrival && (appconfig.footer_new_arrival === 1)
                                ?
                                <ul className="box py-3 " style={{ marginLeft: "20px" }}
                                >
                                    <li className="link_name">New Arrival</li>
                                    <br />
                                    {categories && categories.length !== 0 ? ((categories.length < 4) ? categories.slice(0, categories.length) : categories.slice(0, 4)).map((m, i) => {
                                        return (
                                            <div key={i} >
                                                <Link to={{ pathname: "/shopPage", state: { categories: m.name } }}
                                                // onClick={()=>{
                                                // if(window.location.pathname === "/shopPage"){

                                                // setTimeout(()=>{
                                                //     window.location.reload()
                                                // },1500)
                                                // }
                                                // }}
                                                ><li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>{m.name}</li></Link><br />
                                            </div>
                                        )
                                    })
                                        :
                                        <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500, fontSize: 13 }}>Loading Categories ...</li>
                                    }
                                </ul>
                                : null}


                            {appconfig && appconfig?.footer_accounts && (appconfig.footer_accounts === 1)
                                ?
                                <ul className="box py-3" style={{ marginLeft: "20px" }}>
                                    <Link to={`${process.env.PUBLIC_URL}/`}><li className="link_name">Account</li></Link>
                                    <br />
                                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                        <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>My Account</li>

                                    </Link>
                                    <br />
                                    <Link to={`${process.env.PUBLIC_URL}/myOrders`}>
                                        <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>My Orders</li>

                                    </Link>
                                    <br />
                                </ul>
                                : null}

                            {appconfig && appconfig?.footer_quick_links && (appconfig.footer_quick_links === 1)
                                ?
                                <ul className="box py-3" style={{ marginLeft: "20px" }}>
                                    <li className="link_name">Quick Links</li>
                                    <br />
                                    <Link to={`${process.env.PUBLIC_URL}/shopPage`}><li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>Shop</li></Link><br />
                                    {appconfig && appconfig?.nav_contact && (appconfig.nav_contact === 1)
                                        ?
                                        <>
                                            <Link to={`${process.env.PUBLIC_URL}/contact`}><li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>Contact</li></Link> <br />
                                        </>

                                        : null
                                    }

                                    <Link to={`${process.env.PUBLIC_URL}/`}><li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>Home</li></Link>
                                </ul>
                                : null}


                            {appconfig && appconfig?.footer_follow_us && (appconfig.footer_follow_us === 1)
                                ?
                                <ul className="box py-3" style={{ marginLeft: "20px" }}>
                                    <li className="link_name">Follow Us</li>
                                    <br />

                                    {
                                        sociallinks && sociallinks.data ?
                                            <div>

                                                {
                                                    ((sociallinks.data) ? sociallinks.data[0].is_active : '' === 1) ?
                                                        <>
                                                            <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>
                                                                <a href={contactDetails.data ? contactDetails.data.facebook_page : "/#"} style={{ textDecoration: "none", color: "rgb(26, 26, 26)", fontSize: "13px", fontWeight: 500 }} rel="noopener noreferrer" target="_blank">
                                                                    {sociallinks.data[0].name.substring(0, sociallinks.data[0].name.length - 6)}
                                                                </a></li>
                                                            <br />
                                                        </>
                                                        : null}
                                                {
                                                    ((sociallinks.data) ? sociallinks.data[1].is_active : '' === 1) ?
                                                        <>
                                                            <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>
                                                                <a href={contactDetails.data ? contactDetails.data.instagram_page : "/#"} style={{ textDecoration: "none", color: "rgb(26, 26, 26)", fontSize: "13px", fontWeight: 500 }} rel="noopener noreferrer" target="_blank">
                                                                    {sociallinks.data[1].name.substring(0, sociallinks.data[1].name.length - 6)}
                                                                </a></li>
                                                            <br />
                                                        </>
                                                        : null}
                                                {
                                                    ((sociallinks.data) ? sociallinks.data[2].is_active : '' === 1) ?
                                                        <>
                                                            <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>
                                                                <a href={contactDetails.data ? contactDetails.data.twitter_page : "/#"} style={{ textDecoration: "none", color: "rgb(26, 26, 26)", fontSize: "13px", fontWeight: 500 }} rel="noopener noreferrer" target="_blank">
                                                                    {sociallinks.data[2].name.substring(0, sociallinks.data[2].name.length - 6)}
                                                                </a></li>
                                                            <br />
                                                        </>
                                                        : null}
                                                {
                                                    ((sociallinks.data) ? sociallinks.data[3].is_active : '' === 1) ?
                                                        <>
                                                            <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>
                                                                <a href={contactDetails.data ? contactDetails.data.youtube_page : "/#"} style={{ textDecoration: "none", color: "rgb(26, 26, 26)", fontSize: "13px", fontWeight: 500 }} rel="noopener noreferrer" target="_blank">
                                                                    {sociallinks.data[3].name.substring(0, sociallinks.data[3].name.length - 6)}
                                                                </a></li>
                                                            <br />
                                                        </>
                                                        : null
                                                }
                                            </div>
                                            :
                                            <>
                                                <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500 }}>
                                                    <a style={{ textDecoration: "none", color: "rgb(26, 26, 26)", fontSize: "13px", fontWeight: 500 }} rel="noopener noreferrer" target="_blank">
                                                        No Links Found
                                                    </a></li>
                                                <br />
                                            </>
                                    }

                                </ul>
                                : null}




                        </div>


                        <div className="link-boxes" style={{ paddingTop: 0, display: 'flex', justifyContent: 'space-evenly' }}
                        >
                            {paymentArr && (paymentArr !== false) && (paymentArr.length !== 0)
                                ?
                                <ul className="box py-3" style={{ marginLeft: "20px", width: "auto" }}
                                >
                                    <li className="link_name">Payment Partners</li>
                                    <br />
                                    {paymentArr && (paymentArr !== false) && (paymentArr.length !== 0) ? paymentArr.map((m) => {
                                        return (
                                            <>
                                                {(m?.is_active === 1) && <LazyLoadImage style={{ width: 90 }} key={m} src={m?.icon} />}
                                            </>
                                        )
                                    })
                                        :
                                        <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500, fontSize: 13 }}>Loading Payment Partners ...</li>
                                    }
                                </ul>
                                : null}


                            {shippingArr && (shippingArr !== false) && (shippingArr.length !== 0)
                                ?
                                <ul className="box py-3" style={{ marginLeft: "20px", width: "auto" }}
                                >
                                    <li className="link_name">Shipping Partners</li>
                                    <br />
                                    {shippingArr && (shippingArr !== false) && (shippingArr.length !== 0) ? shippingArr.map((m) => {
                                        return (
                                            <>
                                                {(m?.is_active === 1) && <LazyLoadImage style={{ width: 90 }} key={m} src={m?.icon} />}
                                            </>
                                        )
                                    })
                                        :
                                        <li style={{ color: "rgb(26, 26, 26)", fontWeight: 500, fontSize: 13 }}>Loading Shipping Partners ...</li>
                                    }
                                </ul>

                                : null}
                        </div>



                    </div>





                    <div className="bottom-details text-center py-3 footerB" >
                        <span className="copyright_text" style={{ fontWeight: "600" }}>
                            Copyrighted by {parsedColorCodes && parsedColorCodes?.shop_name} | Powered By <a style={{ color: "#C01D2E", fontWeight: "700" }} rel="noopener noreferrer" href='https://tradnity.com/' target="_blank">Tradnity</a>
                        </span>
                    </div>
                </footer>
            </footer>
        )
    }
}
const mapStateToProps = (state) => ({
    sociallinks: state.contactDetails.socialLinks,
    contactDetails: state.contactDetails.contactDetails,
    categories: state.data.menu,
    appconfigs: (state?.user?.config) ? state?.user?.config : '',
    footerIcons: state?.user?.footerIcons,


})
export default connect(mapStateToProps)(FooterFour);