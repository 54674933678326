import React, { Component } from 'react';
import '../../common/index.scss';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// CSS
import 'react-toastify/dist/ReactToastify.css';

import Demoimage from "../../../assets/images/placeholderC.gif"

// Import custom components

import { fetchImages, getAllProducts, sendSubscriberEmail, fetchBestSeller } from '../../../actions';
import store from '../../../store';
import Collection from "./collection";
import TradnityCarousel from './carousel';
import Collection2 from './collection2';
import Notification from '../../../Notification';
import ScrollToTop from '../../pages/scroll_to_top';
import CategoriesImage from './categoriesImage';
import { Product433 } from '../../../services/script';
import CustomChatbot from '../../chatbot/CustomChatbot';
import Loader from '../../../svg_code/loader';
import SliderLoader from '../../../svg_code/sliderLoader';
import HeadSEO from './headSEO';
import { Link } from 'react-router-dom';

class TradnityMain extends Component {
  constructor(props) {
    super(props);
    this.counterT = 0;
    this.counterB = 0;

    this.state = {
      loadingCategory: true,
      loadingTop: true,
      apicondtionn: false,
      loadingBottom: true,
      subscribeModal: true,
      checkstatus: Cookies.get('SubscribeModal'),
      subscriberEmail: '',
      notification_token: true,
      check_notificaton: Cookies.get('Notification_token'),
      loading: true,
      carousel: '',
    };
  }

  componentDidMount() {
    store.dispatch(fetchImages());
    // setTimeout(() => {
    // this.setState({ apicondtionn: true })
    // localStorage.setItem("apiupdate", this.state.apicondtionn)
     store.dispatch(getAllProducts());
    // store.dispatch(fetchBestSeller());

    // }, 120000);
  }

  imageTopLoaded = () => {
    this.counterT += 1;
    if (this.counterT >= 1) {
      this.setState({ loadingTop: false });
    }
  };

  imageBottomLoaded = () => {
    this.counterB += 1;
    if (this.counterB >= 1) {
      this.setState({ loadingBottom: false });
    }
  };

  closeModal = () => {
    Cookies.set('SubscribeModal', true, { expires: 1 });
  };

 

  render() {
    const { categories, user, appconfigs } = this.props;
    const { subscriberEmail, checkstatus, check_notificaton, loadingTop, loadingBottom } = this.state;

    const colorCodes = localStorage.getItem('color_theme');
    const parsedColorCodes = JSON.parse(colorCodes);
    const appconfig = parsedColorCodes !== null ? parsedColorCodes : appconfigs;

    if (categories && categories.length === 0) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 4000);
    }

    const sendEmail = () => {
        const { subscriberEmail } = this.state;
        const obj = {
          email: subscriberEmail,
        };
        store.dispatch(sendSubscriberEmail(obj));
        this.closeModal();
      };

    const top = user.sliderBannner ? user.sliderBannner.filter((data) => data.title === 'Banner Top') : [];
    const bottom = user.sliderBannner ? user.sliderBannner.filter((data) => data.title === 'Banner Bottom') : [];

    const items = [
      {
        id: appconfig?.slider_option,
        text: appconfig?.slider === 1 ? <TradnityCarousel image={user} /> : null,
      },
      {
        id: appconfig?.new_arrival_option,
        text: appconfig?.new_arrival === 1 ? <Collection type="new" title="New Arrivals" /> : null,
      },
      {
        id: appconfig?.banner1_option,
        text: (
          <>
            {top.length !== 0 &&
              appconfig?.banner1 === 1 &&
              top.map((res, key) => (
                <div key={key} className="mx-4" style={{ display: 'block' }}>
                   {/* paddingBottom: '5%', */}
                  <LazyLoadImage
                    placeholder={<SliderLoader />}
                    className="imgHeightT"
                    src={res?.image_url}
                    alt="Image"
                    width="100%"
                    style={{ borderRadius: 6 }}
                  />
                </div>
              ))}
          </>
        ),
      },
      {
        id: appconfig?.categories_option,
        text: (
          <>
            {appconfig?.categories === 1 ? (
              <section className="pt-3 banner-6 ratio2_1  px-3">
                <div className="title1 title5">
                  <h2 className="title-inner1 mob-heading" style={{ letterSpacing: '3px' }}>
                    CATEGORIES
                  </h2>
                  <hr role="tournament6" />
                </div>
                {categories && categories.length !== 0 ? (
                  <div className="mx-3">
                    <div className="row" style={{ justifyContent: 'center' }}>
                      {localStorage.getItem('rerenderr') == 1 ? (
                        <>
                          {categories.map((m, key) => (
                            <div className={localStorage.getItem('rerenderr') == 1 ? 'col-6 col-md-3 col-lg-2' : 'col-6 col-md-3 col-lg-3'} key={key}>
                              <div>
                                <Link to={{ pathname: '/shopPage', state: { categories: m.name } }}>
                                  <img
                                    id="main-categoery-img"
                                    loading="lazy"
                                    style={{
                                      width: '81%',
                                      borderRadius: '152px',
                                      display: 'block',
                                      height: '640px',
                                      margin: 'auto',
                                    }}
                                    src={m?.image_url}
                                    alt="Category Images"
                                  />
                                </Link>
                                <div className="text-center mt-2">
                                  <Link to={{ pathname: '/shopPage', state: { categories: m.name } }}>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '1em', marginTop: '19px', marginBottom: '62px' }}>
                                      <strong>{m.name}</strong>
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <Slider {...Product433} className="product-4 product-m mx-4">
                          {categories.map((m, key) => (
                            <div className="col-md-3 col-lg-3" key={key}>
                              <div>
                                <Link to={{ pathname: '/shopPage', state: { categories: m.name } }}>
                                  <img
                                    id="main-categoery-img"
                                    style={{
                                      width: '100%',
                                      height: '640px',
                                      margin: 'auto',
                                    }}
                                    src={m?.image_url}
                                    alt="Category Images"
                                  />
                                </Link>
                                <div className="text-center mt-2">
                                  <Link to={{ pathname: '/shopPage', state: { categories: m.name } }}>
                                    <h3 style={{ textTransform: 'uppercase', fontSize: '1em', marginTop: '19px', marginBottom: '62px' }}>
                                      <strong>{m.name}</strong>
                                    </h3>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center', marginTop: '7%', marginBottom: '7%' }}>
                    <Loader />
                  </div>
                )}
              </section>
            ) : null}
          </>
        ),
      },
      {
        id: appconfig?.banner2_option,
        text: (
          <>
            {bottom.length !== 0 &&
              appconfig?.banner2 === 1 &&
              bottom.map((res, key) => (
                <div key={key} className="mx-4" style={{ paddingBottom: '5%', display: 'block' }}>
                  <LazyLoadImage
                    placeholder={<SliderLoader />}
                    className="imgHeightT"
                    src={res?.image_url}
                    alt="Image"
                    width="100%"
                    style={{ borderRadius: 6 }}
                  />
                </div>
              ))}
          </>
        ),
      },
      {
        id: appconfig?.featured_option,
        text: appconfig?.featured === 1 ? <Collection2 type="isfeatured" title="Featured Products" /> : null,
      },
      {
        id: appconfig?.subscribe_option,
        text: (
          <>
            {this.state.checkstatus !== true && appconfig?.subscribe === 1 ? (
              <div id="content">
                <h2>Subscribe Now</h2>
                <p>Be the first to learn about our latest trends and get exclusive offers.</p>
                <input
                  type="email"
                  className="my-2 inpEmail"
                  style={{ minWidth: 300, maxWidth: '60vw' }}
                  onChange={(e) => this.setState({ subscriberEmail: e.target.value })}
                  placeholder="Enter your email"
                />
                <button id="subscribe" disabled={this.state.subscriberEmail !== '' ? false : true} className="btn btn-solid my-2" style={{ maxWidth: 200 }} onClick={() => sendEmail()}>
                  Subscribe
                </button>
              </div>
            ) : null}
          </>
        ),
      },
    ];

    const sortedItems = items.sort((a, b) => a.id - b.id);

    return (
      <div>
        <ScrollToTop />
        {this.state.notification_token === true && this.state.check_notificaton !== 'true' ? (
          <>
            <Notification />
            {this.setState({ notification_token: false })}
            {Cookies.set('Notification_token', true, { expires: 10 })}
          </>
        ) : null}
        <HeadSEO title="Home" />
        <div style={{ marginTop: '32px' }} />
        {sortedItems.map((item, index) => (
          <div key={item.id}>{item.text}</div>
        ))}
        <CustomChatbot />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.images,
  categories: state.data.menu,
  appconfigs: state?.user?.config ?? '',
});

export default connect(mapStateToProps)(TradnityMain);
