import React from 'react';

function EmptySearch() {

	const colorCodes = localStorage.getItem("color_theme");
	let parsedColorCodes = JSON.parse(colorCodes);


    return( 
<>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="250px" height="250px" viewBox="0 0 1920.000000 1920.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)"
fill={parsedColorCodes?.color ? parsedColorCodes?.color : '#000'} stroke="none">
<path d="M11569 15285 c-675 -114 -1189 -710 -1189 -1382 0 -94 -3 -113 -15
-113 -8 0 -68 13 -133 30 -133 33 -354 73 -517 91 -140 16 -523 16 -680 0
-811 -84 -1541 -422 -2118 -981 -421 -407 -723 -892 -906 -1451 -228 -699
-226 -1489 4 -2184 177 -536 457 -995 850 -1391 557 -562 1262 -911 2060
-1019 164 -23 566 -31 747 -16 564 48 1105 230 1597 539 l104 65 26 -30 c98
-112 382 -455 386 -465 3 -7 -20 -33 -50 -58 -113 -92 -148 -191 -109 -307 19
-56 9 -43 349 -448 143 -170 357 -425 475 -566 1353 -1612 1317 -1571 1410
-1626 149 -90 376 -88 531 4 61 36 388 312 435 366 53 63 102 172 116 260 15
98 0 206 -42 294 -35 75 -45 89 -228 307 -171 204 -439 524 -773 922 -134 160
-379 452 -544 649 -165 197 -366 436 -446 532 -186 222 -229 251 -349 240 -68
-6 -107 -25 -177 -83 -28 -24 -54 -44 -57 -44 -8 0 -32 27 -247 282 l-178 212
45 51 c194 218 306 362 412 531 294 468 460 941 528 1499 29 240 25 608 -11
888 l-7 57 201 0 201 0 0 -197 c1 -216 7 -271 37 -312 49 -66 149 -89 221 -52
20 11 171 141 334 289 l297 269 193 6 c136 4 222 12 290 27 269 56 505 182
704 373 248 238 396 541 434 889 14 132 14 1656 -1 1786 -19 180 -63 332 -140
490 -203 411 -591 697 -1053 777 -77 13 -279 15 -1515 14 -1107 -1 -1443 -4
-1502 -14z m3005 -100 c244 -43 481 -156 658 -314 226 -201 360 -432 431 -743
20 -90 21 -122 24 -923 5 -994 2 -1044 -78 -1272 -115 -326 -353 -594 -668
-753 -194 -97 -355 -130 -628 -130 l-184 0 -52 -49 c-29 -27 -175 -159 -326
-295 -292 -261 -302 -268 -348 -224 -23 21 -23 22 -23 295 l0 273 -858 0
c-956 0 -960 0 -1157 70 -158 56 -336 163 -456 275 -220 204 -367 476 -414
765 -22 138 -23 1794 0 1921 23 136 62 256 120 376 185 380 548 652 970 727
124 22 2863 23 2989 1z m-4858 -2065 c180 -23 402 -75 554 -129 l105 -37 6
-379 c5 -341 7 -390 27 -482 122 -566 531 -982 1097 -1114 73 -17 135 -22 323
-26 202 -5 234 -8 241 -22 5 -9 17 -68 27 -131 27 -164 27 -642 1 -809 -50
-311 -153 -624 -289 -881 -375 -710 -1052 -1229 -1822 -1400 -240 -53 -348
-65 -616 -64 -201 0 -270 4 -385 22 -426 66 -791 205 -1125 427 -174 116 -274
198 -431 355 -626 626 -914 1519 -768 2385 100 596 372 1114 801 1531 433 420
994 688 1588 758 181 22 481 20 666 -4z"/>
<path d="M12409 13866 c-55 -19 -96 -53 -123 -99 -36 -60 -42 -113 -21 -183
16 -57 26 -68 253 -296 l236 -238 -236 -237 c-219 -222 -237 -242 -253 -294
-23 -73 -14 -133 28 -196 38 -57 119 -103 180 -103 95 0 107 8 365 264 l242
240 233 -231 c244 -244 282 -272 363 -273 127 0 233 99 234 220 0 101 -5 107
-263 368 l-241 242 236 238 c155 155 243 250 253 274 45 109 -3 234 -110 291
-53 28 -155 29 -205 3 -19 -10 -140 -122 -268 -250 l-232 -230 -233 231 c-244
245 -282 273 -362 272 -22 0 -56 -6 -76 -13z"/>
<path d="M8519 3049 c-1150 -16 -1990 -59 -2121 -108 -20 -7 -16 -10 32 -20
599 -123 4566 -147 5880 -36 183 16 283 30 322 44 22 8 22 8 3 14 -274 81
-2270 133 -4116 106z"/>
</g>
</svg>

</>
        );
}


export default EmptySearch;

