import "./productSlider.scss";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Navigation, Thumbs } from "swiper";
import { useState } from "react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import React from "react";
import Placeholder from "../../../assets/images/placeholder.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Magnifier from "react-magnifier";

function getYoutubeThumbnail(url) {
  let videoID;
  const embedMatch = url.match(/embed\/(\w+)/);
  const watchMatch = url.match(/[?&]v=([^&#]+)/);

  if (embedMatch) {
    videoID = embedMatch[1];
  } else if (watchMatch) {
    videoID = watchMatch[1];
  } else {
    return null;
  }

  return `http://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
}

const ProductImagesSlider = (props) => {
  const [activeThumb, setActiveThumb] = useState(null);
  const videoUrl = props.item.api.videos.map((v) => {
    return { video_url: v.url, type: "video" };
  });
  let modifiedYoutubeUrl =
    props.item.api.youtube_link.indexOf("?") !== -1
      ? props.item.api.youtube_link + "&autoplay=1&mute=1&rel=0"
      : props.item.api.youtube_link + "?autoplay=1&mute=1&rel=0";

  const allUrl = [...videoUrl, ...props.images];
  if (props.item.api.youtube_link) {
    allUrl.push({ youtube_link: modifiedYoutubeUrl, type: "youtube_link" });
  }
  return (
    <>
      <span style={{ textAlign: "center" }}>
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          style={{
            border: "1px solid rgb(236 132 95 / 50%)",
            borderRadius: "6px",
            height: "auto",
          }}
          modules={[Navigation, Thumbs]}
          grabCursor={true}
          thumbs={{ swiper: activeThumb }}
          className="product-images-slider my-2 respBig myImgWidth"
        >
          {allUrl &&
            allUrl.length !== 0 &&
            allUrl.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {item?.type === "youtube_link" ? (
                    <div style={{ margin: "0px" }}>
                      <iframe
                        width="100%"
                        height="609"
                        allow="autoplay"
                        src={item.youtube_link}
                        frameborder="0"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ) : item?.type === "video" ? (
                    <div style={{ margin: "0px" }}>
                      <video
                        controls
                        autoPlay
                        style={{ height: "609px", width: "100%" }}
                        loading="lazy"
                      >
                        <source src={item.video_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <Magnifier
                      className="setHeight"
                      height="25em"
                      src={
                        item && typeof item == "string"
                          ? item
                          : item.original_image_url
                          ? item.original_image_url
                          : Placeholder
                      }
                      width="100%"
                    />
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
        <Swiper
          onSwiper={setActiveThumb}
          loop={true}
          spaceBetween={10}
          style={
            allUrl && allUrl.length === 1
              ? { width: "100px", borderRadius: "6px" }
              : allUrl.length === 2
              ? { width: "200px", borderRadius: "6px" }
              : allUrl.length === 3
              ? { width: "300px", borderRadius: "6px" }
              : { width: "300px", borderRadius: "6px" }
          }
          slidesPerView={allUrl.length === 1 ? 1 : allUrl.length === 2 ? 2 : 3}
          modules={[Navigation, Thumbs]}
          className="product-images-slider-thumbs"
        >
          {props &&
            allUrl &&
            allUrl.length !== 0 &&
            allUrl.map((item, index) => (
              <div key={index}>
                <SwiperSlide key={index}>
                  {item?.type === "youtube_link" ? (
                    <img
                      style={{ height: "117px", width: "91px" }}
                      src={getYoutubeThumbnail(item.youtube_link)}
                      title="YouTube Video"
                      alt="YouTube Video"
                    />
                  ) : item?.type === "video" ? (
                    <div>
                      <video
                        loading="lazy"
                        style={{ height: "117px", width: "91px" }}
                      >
                        <source src={item.video_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <LazyLoadImage
                      onLoad={() => props.imageLoad()}
                      src={
                        item && typeof item == "string"
                          ? item
                          : item.original_image_url
                          ? item.original_image_url
                          : Placeholder
                      }
                      style={{ display: "block", width: "100%" }}
                      alt="product images"
                    />
                  )}
                </SwiperSlide>
              </div>
            ))}
        </Swiper>
      </span>
    </>
  );
};
ProductImagesSlider.propTypes = {
  images: PropTypes.array.isRequired,
};
export default ProductImagesSlider;
