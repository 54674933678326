// Get Products
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT'
//Get Menu
export const FETCH_MENU = 'FETCH_MENU'
export const FETCH_FAQ = "FETCH_FAQS"
//get images
export const FETCH_SLIDER_IMAGE = 'FETCH_SLIDER_IMAGE'
export const FETCH_ROUTE_P_NAME = 'FETCH_ROUTE_P_NAME'
export const FETCH_HOME_BANNER = 'FETCH_HOME_BANNER'
// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND'
export const FILTER_COLOR = 'FILTER_COLOR'
export const FILTER_PRICE = 'FILTER_PRICE'
export const SORT_BY = 'SORT_BY'
export const FILTER_COUNTRY = 'FILTER_COUNTRY'
export const FILTER_SEARCH = 'FILTER_SEARCH'
export const CHANGE_CURRENCY = 'CHANGE_CURRENCY'
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY'
// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'
// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'
// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'
// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
export const USERS_DETAILS = 'USERS_DETAILS'
export const ORDER_REF = 'ORDER_REF'
export const CLEAR_CART = 'CLEAR_CART'
export const FETCH_DEALS = 'FETCH_DEALS'
export const FETCH_NEW_DEALS = 'FETCH_NEW_DEALS'

export const FETCH_SETTINGS = 'FETCH_SETTINGS'
export const FETCH_PRIVATEPOLICY = 'FETCH_PRIVATEPOLICY'
export const FETCH_SOCIALLOGIN = 'FETCH_SOCIALLOGIN'
export const ORDER_REF_STATUS = 'ORDER_REF_STATUS'
export const APPLY_COUPON = 'APPLY_COUPON'
export const REVIEWS_DETAILS = 'REVIEWS_DETAILS'
export const FETCH_DEALS_BANNERS = 'FETCH_DEALS_BANNERS'
export const GETUSER_INFO = 'GETUSER_INFO'
export const GETCONTACT_DETAILS = 'GETCONTACT_DETAILS'
export  const GETSIDEDETIALS = 'GETSIDEDETIALS';
export  const GETBESTSELLER = 'GETBESTSELLER';
export const GETUSER_ADDRESS = 'GETUSER_ADDRESS'
export const CURRENT_PAGE = 'CURRENT_PAGE'
export const GET_REVIEWS = 'GET_REVIEWS'
export const GET_WISHLIST = 'GET_WISHLIST'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_MAIN_SCREEN_TEXT = 'GET_MAIN_SCREEN_TEXT'
export const GET_FOOTER_ICONS = 'GET_FOOTER_ICONS'
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CHANGE_CURRENCIES = 'GET_CHANGE_CURRENCIES'
export const GET_VIEW_DETAILS = 'GET_VIEW_DETAILS'
export const GET_CART = 'GET_CART'
export const GET_FILLTER_PRODUCT = 'GET_FILLTER_PRODUCT'
export const GET_CONFIRM_ORDER = 'GET_CONFIRM_ORDER'
export const POST_REVIEWS = 'POST_REVIEWS'
export const GETUSER_ADDRESSBYID = 'GETUSER_ADDRESSBYID'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_APP_CONFIG = 'GET_APP_CONFIG'
export const FORGET_EMAIL = 'FORGET_EMAIL'
export const GET_ALL_PAGES = 'GET_ALL_PAGES'