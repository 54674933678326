import React, { Component } from 'react';
import Parser from 'html-react-parser';
import ScrollToTop from './scroll_to_top';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from '../../svg_code/loader';
import HeadSEO from '../layouts/tradnity/headSEO';


class AboutUsStructure extends Component {
    constructor(props) {
        super(props);
        this.counter = React.createRef(0);
        this.state = {
            loadingCategory: true,
            loadingCategoryS: true,
            loading:true
        };
    }
    render() {
        const { result, about, about_side } = this.props;
     
          
 
      
        return (
            <div className="about-top" style={{paddingTop:"2%"}}>
                <ScrollToTop />
                <HeadSEO title="About Us" />
            
                       

                {about && about.length !== 0 && about.map((res,keys) => (
                    <div key={keys}>
                        <LazyLoadImage 
                         style={{width: "100%", height: "100%" }}
                            src={res?.image_url} />
                    </div>
                ))}
                <div className="row my-5" style={{justifyContent: "center",    alignItems: "center"}}>
                {
                    result && result.length !== 0 ?  result.map((res, keys) => {
                        return(
                    <div key={keys} className="col-lg-6 col-md-6 base_bg_ligh" style={{ height: "100%", paddingTop: '5%', paddingBottom:"5%" }}>

                                <div style={{fontFamily:"Poppins"}} className="mx-3">
                                    {Parser(res.PageContent)}
                                </div>
                    </div>
                            )})
                            :
                           

                            <div className="text-center my-5">

<Loader />

</div> 



                        }
                    <div className="col-lg-6 col-md-6 text-center" style={{textAlign:"center"}}>
                        {about_side && about_side.length !== 0 && about_side.map((res,keys) => (
                            <div key={keys} style={{textAlign:"center",width:"80%", margin:"auto"}}>
                             
                                <LazyLoadImage  src={res.image_url} width="100%" height="100%" style={{ paddingTop: '0%', display:"block"}} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUsStructure;