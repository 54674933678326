import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import "./proCard.css"
import "./product-item.scss"
import Heart from "react-heart"
import { getRelatedItems } from "../../../services";
import { removeWishlist } from '../../../actions';
import store from '../../../store';
import { Modal } from 'antd';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaRegStar, FaStar } from "react-icons/fa";
import LeftSidebar from '../../products/left-sidebar';
import history from '../../../history';
import Placeholder from '../../../svg_code/placeholder';
import '../../../assets/css/custom.css'

class ProductItem extends Component {
  constructor(props) {
    super(props)
    this.counter1R = React.createRef(0);
    this.counter2R = React.createRef(0);
    this.state = {
      open: false,
      cartModalopen: false,
      stock: 'InStock',
      quantity: 1,
      image: '',
      click: false,
      wishList: this.props.product.wishlist,
      show: false,

    }
  }

  onClickHandle(img) {
    this.setState({ image: img });
  }
  onOpenModal = () => {
    if (window.innerWidth < 750) {
      this.setState({ open: false });
    } else { this.setState({ open: true }); }
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenCartModal = () => {
    if (window.innerWidth < 750) {
      this.setState({ cartModalopen: false });
      this.props.onAddToCartClicked();
    } else {
      this.setState({ cartModalopen: true });
      this.props.onAddToCartClicked();
    }
  };
  onCloseCartModal = () => {
    this.setState({ cartModalopen: false });
  };
  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({ stock: 'InStock' })
      this.setState({ quantity: this.state.quantity - 1 })
    }
  }
  plusQty = () => {
    if (this.props.product.stock >= this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 })
    } else {
      this.setState({ stock: 'Out of Stock !' })
    }
  }
  changeQty = (e) => {
    this.setState({ quantity: parseInt(e.target.value) })
  }
  setActive = (e) => {
    this.setState({ wishList: e });
  }
  render() {
    const { product, appconfigs } = this.props;

    const { wishList } = this.state;
    let RatingStars = []
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i}></i>)
    }
    if (!this.state.open) {
      document.body.style.overflowY = "auto";
    }
    const decryptedData = localStorage.getItem("customerData")

    const colorCodes = localStorage.getItem("color_theme");
    let parsedColorCodes = JSON.parse(colorCodes);
    let appconfig = parsedColorCodes && (parsedColorCodes !== null) ? parsedColorCodes : appconfigs;

    return (
      <>
        {
          product
            ?

            <>

              <div className="main-filter-content">
                <div className="product-collection products-grid row">

                  <div className="grid-item">

                    <div className="inner product-item on-sale borderProduct" style={{
                      padding: '10px', borderRadius: '4px',
                    }}>
                      <div className="inner-top">
                        <div className="product-top">
                          <div className="product-image image-swap">
                            <div className="product-grid-image" >
                              <Link to={`${process.env.PUBLIC_URL}/product/${product.api.url_key}`}>

                                <span className="images-one">
                                  {
                                    (product
                                      && product?.api
                                      && product?.api?.images.length !== 0
                                      && product?.api?.images[0]
                                      && product?.api?.images[0]?.original_image_url) ?

                                      <LazyLoadImage placeholder={<Placeholder />} effect="opacity" alt="Product Images" style={{ height: '435px', objectFit: "cover", minWidth: '100%' }}
                                        src={product && product.api && product.api.images.length !== 0 && product.api.images[0] && product?.api?.images[0]?.original_image_url} />

                                      :

                                      <Placeholder />
                                  }
                                </span>
                              </Link>

                              <Link to={`${process.env.PUBLIC_URL}/product/${product.api.url_key}`}>

                                <span className="images-two">
                                  {
                                    (product
                                      && product.api
                                      && product.api.images.length !== 0
                                      && product.api.images.length !== 1
                                      && product.api.images[0]
                                      && product.api.images[1]
                                      && product.api.images[0].original_image_url
                                      && product.api.images[1].original_image_url
                                      ? product.api.images[1].original_image_url
                                      : product.api.images.length !== 0
                                      && product.api.images[0]
                                      && product.api.images[0].original_image_url) ?

                                      <LazyLoadImage
                                      style={{
                                        height: '435px',
                                        objectFit: "cover",
                                        minWidth: '100%'
                                      }}
                                      placeholder={<Placeholder />}
                                      effect="opacity"
                                      alt="Product Images1"
                                      src={product
                                        && product.api
                                        && product.api.images.length !== 0
                                        && product.api.images.length !== 1
                                        && product.api.images[0]
                                        && product.api.images[1]
                                        && product.api.images[0].original_image_url
                                        && product.api.images[1].original_image_url
                                        ? product.api.images[1].original_image_url
                                        : product.api.images.length !== 0
                                        && product?.api?.images[0]
                                        && product?.api?.images[0]?.original_image_url} />
                                      :
                                      <Placeholder />

                                  }
                                </span>

                              </Link>

                            </div>
                          </div>

                          {appconfig && appconfig?.navbar_wishlist && (appconfig.navbar_wishlist === 1)
                            ?
                            <div style={{ width: '2rem', position: 'absolute', top: '0px', left: '5px' }}>

                              <Heart className="heartColor" isActive={wishList} onClick={() => {
                                if (decryptedData) {

                                  this.setActive(!wishList);
                                  store.dispatch(removeWishlist(product.id))
                                } else {
                                  history.push(`${process.env.PUBLIC_URL}/login`)
                                }
                              }} />

                            </div>
                            : null
                          }

                          {(product && product.api && product.api.Discount_Percentage) ?
                            <div className="product-label">

                              <strong className="label sale-label">-{product.api.Discount_Percentage}</strong>
                            </div>
                            :
                            null
                          }

                          <br />

                          <div className="product-des abs-center my-2">

                            <div className="action">

                              <button className="btn btn-solid" onClick={() => this.setState({ show: true })} >
                                Add To Cart
                              </button>

                            </div>

                            <span className="stamped-product-reviews-badge" style={{ display: "block" }}></span>

                          </div>

                        </div>

                        <div className="product-bottom">
                          <Link to={`${process.env.PUBLIC_URL}/product/${product.api.url_key}`} >
                            <div className="product-vendor">
                              {(product && product.api && product.api.Category && product.api.Category.length !== 0) ?
                                ((product.api.Category.length > 2) ? product.api.Category.slice(0, 2) : product.api.Category).map((cat, key) => (
                                  <span style={{ display: "inline-block" }} key={key}>{cat.name}{(key === (product?.api?.Category.length - 1)) ? " " : ", "}</span>
                                ))
                                :
                                <span></span>
                              }
                            </div>
                          </Link>

                          <Link to={`${process.env.PUBLIC_URL}/product/${product.api.url_key}`} className="product-title" style={{ fontSize: 14 }} >

                            <span>
                              {product.name}</span>

                          </Link>

                          <div className="price-box my-2" style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "column" }}>

                            <div className="price-sale">
                              {(product.api.formated_regular_price !== null) ? <span className="old-price"><span className="money done" ws-price="1590" ws-currency="PKR">{product.api.formated_regular_price}</span></span> : null}

                              <span className="special-price">
                                <span className="money done" ws-price="1431" ws-currency="PKR">{(product.api.formated_price) ? product.api.formated_price : (product.api.formated_price) ? product.api.formated_price : ''}
                                  {/* {product.api.formated_regular_price !== product.api.formated_price ?<p className="last-price fs-5"> &nbsp; <span className="px-1 text-danger"> {product.api.formated_regular_price !== "" && product.api.formated_regular_price}</span></p>:null} */}
                                </span>
                              </span>
                            </div>

                            <div className="mx-2">

                              {product?.api?.reviews?.average_rating && (product?.api?.reviews?.average_rating !== '0') ?
                                <FaStar style={{ color: "yellow" }} />
                                : <FaRegStar style={{ color: "yellow" }} />} <span className='' style={{ fontSize: 12, color: "rgba(0,0,0,0.7)" }}>({product?.api?.reviews?.average_rating && (product?.api?.reviews?.average_rating !== '0') ? product.api.reviews.average_rating : '0.0'})({product?.api?.reviews?.total ? product.api.reviews.total : '0'})</span>
                            </div>

                          </div>

                        </div>

                      </div>
                      <button className="btn btn-solid me" onClick={() => this.setState({ show: true })} >
                        Add To Cart
                      </button>

                    </div>

                  </div>

                </div>
              </div>

              <Modal
                title={`${product.name} Product Details`}
                centered
                visible={this.state.show}
                okText="View Product Details"
                onOk={() => history.push(`${process.env.PUBLIC_URL}/product/${product?.api?.url_key}`)}
                onCancel={() => this.setState({ show: false })}
                width={1000}
                zIndex={999999999999}
                style={{ backgroundColor: "red" }}
              >
                <LeftSidebar url={product?.api?.url_key} CutLoading={this.state.show} />
              </Modal>

            </>

            :
            <div style={{ height: 500, width: "100%", textAlign: "center" }}>
              No Products Available
            </div>
        }
      </>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  relatedItems: getRelatedItems(state.data.products, ownProps.product.category, state.filters.country),
  appconfigs: (state?.user?.config) ? state?.user?.config : '',

})
export default connect(mapStateToProps)(ProductItem);
