import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux'
import store from '../../../store';
import { Product4 } from '../../../services/script'
import { addToCart, addToWishlist, addToCompare, removeWishlist, addItemToCart } from "../../../actions";
import ProductItem from './product-item';
import "./proCard.css"
import Loader from '../../../svg_code/loader';
import history from '../../../history';

class Collection2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    render() {
        function handleClick(e) {
            store.dispatch(removeWishlist(e));
        }
        function addItemTOCart(e) {
            document.querySelector(".loader-wrapper").style = "display: block";
            var item = { 'product_id': e, 'quantity': 1 }
            store.dispatch(addItemToCart(item));
        }
        const { items, symbol, addToCompare, title, subtitle, } = this.props;
        if(items && items.length === 0){
 
            setTimeout(()=>{
          
                this.setState({loading : false})
              },5000)
          
          }
          function checkFeatured(feature) {
            return feature?.api?.featured === 1;
          }
            let featured = (items.length !== 0) && items.filter(checkFeatured)
            
        return (
            <div>
                <section className="section-b-space j-box pets-box ratio_square  px-3">
                        <div className="row">
                            <div className="col">
                                <div className="title1 title5">
                                    {subtitle ? <h4 style={{ fontStyle: 'italic', fontSize: '14px', color: '#777777', letterSpacing: '0px' }}>{subtitle}</h4> : ''}
                                    <h2 className="title-inner1 mob-heading" style={{ letterSpacing: '3px' }}>{title}</h2>
                                    <hr role="tournament6" />
                                </div>


                                {featured && featured.length !== 0 ?


<Slider {...Product4} className="product-4 product-m mx-4">
{featured && featured.map((product, index) =>
                                        <div key={index}>
                                            <ProductItem product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => localStorage.getItem("customerData") ? handleClick(product.id) : history.push(`${process.env.PUBLIC_URL}/login`)}
                                                onAddToCartClicked={() => addItemTOCart(product.id)} key={index} />
                                        </div>)
                                    }
                                </Slider>

:
                         
<div style={{textAlign:"center", marginTop:"7%", marginBottom:"7%", }}>
    <Loader />
                              

                           </div>
             
                                            }
                            </div>
                        </div>
                    {/* </div> */}
                </section>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        items: state?.data?.deals,
        symbol: state.data.symbol,
    }
}
export default connect(mapStateToProps, { addToCart, addToWishlist, addToCompare })(Collection2);