export const Product2a = {
    infinite: true,
    speed: 300,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    adaptiveHeight: true,
    autoplaySpeed: 3000,
    cssEase: "linear",

  
    responsive: [
        {
            breakpoint: 2250,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1850,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Product4 = {
    infinite: true,
    speed: 300,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    adaptiveHeight: true,
    autoplaySpeed: 1800,
    cssEase: "linear",

  
    responsive: [
        {
            breakpoint: 2250,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1850,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const Product433 = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,  // Changed from 5 to 4
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    adaptiveHeight: true,
    autoplaySpeed: 1800,
    cssEase: "linear",

    responsive: [
        {
            breakpoint: 2250,
            settings: {
                slidesToShow: 4,  // Changed from 6 to 4
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1850,
            settings: {
                slidesToShow: 4,  // Changed from 5 to 4
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,  // Changed from 4 to 4
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

export const SliderH = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
}
export const Product5 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}



export const Team4 = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 586,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
}



export const Slider2 = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const Slider3 = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Slider4 = {
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 586,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}
export const Slider5 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

export const Slider6 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }

    ]
}

export const Slider7 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
        {
            breakpoint: 1367,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
}


export const SlideUpDown = function (classnames) {
    var accords = document.querySelectorAll("." + classnames + " h4");
    [].forEach.call(accords, function (elemt) {
        elemt.innerHTML += '<span className="according-menu"></span>';
    })

    var anchors = document.getElementsByClassName(classnames);
    for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.onclick = function () {
            var elems = document.querySelectorAll("." + classnames);
            [].forEach.call(elems, function (elemt) {
                elemt.classList.remove("active");
                var el = elemt.nextElementSibling;
                el.style.height = el.offsetHeight + 'px'
                el.style.transitionProperty = `height, margin, padding`
                el.style.transitionDuration = '500ms'
                el.offsetHeight // eslint-disable-line no-unused-expressions
                el.style.overflow = 'hidden'
                el.style.height = 0
                el.style.paddingTop = 0
                el.style.paddingBottom = 0
                el.style.marginTop = 0
                el.style.marginBottom = 0
                el.style.display = 'none'
                el.style.removeProperty('height')
                el.style.removeProperty('padding-top')
                el.style.removeProperty('padding-bottom')
                el.style.removeProperty('margin-top')
                el.style.removeProperty('margin-bottom')
                el.style.removeProperty('overflow')
                el.style.removeProperty('transition-duration')
                el.style.removeProperty('transition-property')

            });

            this.classList.add('active');
            var element = this.nextElementSibling;
            element.style.removeProperty('display')
            let display = window.getComputedStyle(element).display

            if (element.classList.contains('show')) {
                element.classList.remove('show');

                this.classList.remove("active");
                element.style.height = element.offsetHeight + 'px'
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.offsetHeight // eslint-disable-line no-unused-expressions
                element.style.overflow = 'hidden'
                element.style.height = 0
                element.style.paddingTop = 0
                element.style.paddingBottom = 0
                element.style.marginTop = 0
                element.style.marginBottom = 0
                element.style.display = 'none'
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.style.removeProperty('height')
                element.style.removeProperty('padding-top')
                element.style.removeProperty('padding-bottom')
                element.style.removeProperty('margin-top')
                element.style.removeProperty('margin-bottom')
                window.setTimeout(function () {
                    element.style.removeProperty('overflow')
                    element.style.removeProperty('transition-duration')
                    element.style.removeProperty('transition-property')
                }, 500)

            } else {
                element.classList.add('show');
                if (display === 'none') display = 'block'
                element.style.display = display
                let height = element.offsetHeight
                element.style.overflow = 'hidden'
                element.style.height = 0
                element.style.paddingTop = 0
                element.style.paddingBottom = 0
                element.style.marginTop = 0
                element.style.marginBottom = 0
                element.offsetHeight // eslint-disable-line no-unused-expressions
                element.style.transitionProperty = `height, margin, padding`
                element.style.transitionDuration = '500ms'
                element.style.height = height + 'px'
                element.style.removeProperty('padding-top')
                element.style.removeProperty('padding-bottom')
                element.style.removeProperty('margin-top')
                element.style.removeProperty('margin-bottom')
                window.setTimeout(function () {
                    element.style.removeProperty('height')
                    element.style.removeProperty('overflow')
                    element.style.removeProperty('transition-duration')
                    element.style.removeProperty('transition-property')
                }, 500)
            }
        }
    }

    var elems = document.querySelectorAll("." + classnames);
    [].forEach.call(elems, function (elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: none";
    });

}


