import React from 'react';

function Placeholder() {

    const colorCodes = localStorage.getItem("color_theme");
    let parsedColorCodes = JSON.parse(colorCodes);

    return( 


<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100%" height="100%" viewBox="0 0 300.000000 450.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,450.000000) scale(0.100000,-0.100000)"
fill={parsedColorCodes?.color_rgb ? `rgba(${parsedColorCodes?.color_rgb}, 0.85)` : '#000'} stroke={parsedColorCodes?.color_rgb ? `rgba(${parsedColorCodes?.color_rgb}, 0.85)` : '#000'}>
<path d="M0 2250 l0 -2250 1500 0 1500 0 0 2250 0 2250 -1500 0 -1500 0 0
-2250z m1750 573 c7 -21 18 -51 25 -68 16 -36 55 -144 55 -150 0 -3 -172 -5
-382 -5 -230 0 -378 4 -372 9 5 5 54 26 109 46 55 20 109 40 120 45 11 5 52
21 90 36 146 55 201 77 240 94 39 17 68 26 92 29 6 0 17 -16 23 -36z m-810
-579 c0 -282 -6 -346 -25 -281 -4 12 -18 46 -31 75 -13 29 -24 58 -24 66 0 7
-3 16 -7 20 -4 3 -8 14 -9 23 -2 10 -7 26 -13 38 -5 11 -33 80 -62 154 -57
149 -59 139 26 167 28 10 68 25 90 35 21 10 43 15 47 13 4 -3 8 -142 8 -310z
m1188 -131 l-3 -378 -542 -3 -543 -2 0 27 c-1 22 0 719 0 731 0 1 245 2 545 2
l545 0 -2 -377z"/>
<path d="M1210 2335 c-46 -49 -29 -133 31 -154 48 -17 66 -14 101 18 28 25 33
36 32 72 -2 57 -37 89 -98 89 -33 0 -48 -6 -66 -25z"/>
<path d="M1691 2225 c-24 -36 -47 -65 -53 -65 -5 0 -7 -4 -4 -9 3 -5 -8 -26
-24 -46 -17 -21 -40 -54 -52 -74 -40 -64 -53 -74 -80 -60 -12 6 -36 23 -51 35
-16 13 -35 24 -43 24 -9 0 -34 -21 -57 -47 -23 -26 -61 -67 -84 -91 -24 -24
-43 -48 -43 -53 0 -5 176 -9 405 -9 223 0 405 3 405 8 0 19 -100 336 -108 344
-7 7 -23 5 -47 -3 -41 -15 -36 -19 -80 66 -11 21 -24 40 -30 42 -5 1 -30 -27
-54 -62z"/>
</g>
</svg>

);
}

export default Placeholder;

